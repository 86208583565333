import * as React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

export interface ISEOMetas {
    description?: string
    extraMetas?: any[]
    title?: string
}

function Seo({ description, extraMetas, title }: ISEOMetas) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const metaTitle = title || site.siteMetadata.title
    const pageOwner = "Amarillo® Hop"

    const composedMeta = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: metaTitle,
        },
        {
            property: `og:url`,
            content: `https://amarillohop.com`,
        },
        {
            property: `og:image`,
            content: `https://amarillohop.com`,
        },
        {
            property: `og:image:width`,
            content: 1920,
        },
        {
            property: `og:image:height`,
            content: 1082,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `twitter:card`,
            content: `summary`,
        },
        {
            property: `twitter:creator`,
            content: site.siteMetadata.author,
        },
        {
            property: `twitter:title`,
            content: metaTitle,
        },
        {
            property: `twitter:description`,
            content: metaDescription,
        },
        ...!!extraMetas ? extraMetas : [],
    ]

    return (
        <Helmet
            title={metaTitle}
            titleTemplate={`%s | ${pageOwner}`}
            meta={composedMeta}
        />
    )
}

export default Seo
