export const SEOPagesDescription = {
  "home-page": {
    title: "Home",
    description: "Welcome to Amarillo® Hop!",
  },
  "about": {
    title: "About us",
  },
  "brand": {
    title: "Branding",
  },
  "hop-pdfs": {
    title: "Hop PDFs",
  },
  "hops-for-sale": {
    title: "Hops for Sale",
  },
  "news-media": {
    title: "News and Media",
  },
  "spyder-chart": {
    title: "Spyder Chart",
  },
  "thank-you": {
    title: "Thank You",
  },
};
